<template>
  <GalleryEditView disabled :is-modal="isModal" :gallery-id="galleryId" />
</template>

<script>
import GalleryEditView from '@/views/gallery/GalleryEditView'

export default {
  name: 'GalleryView',
  components: {
    GalleryEditView
  },
  props: {
    galleryId: {
      type: Number,
      required: false
    },
    isModal: {
      type: Boolean,
      default: false
    }
  }
}
</script>
