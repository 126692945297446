<script>
import ModuleView from '../../components/ModuleView'
import GalleryNewView from './GalleryNewView'
import NotifyService from '../../services/NotifyService'

export default {
  name: 'GalleryEditView',
  extends: GalleryNewView,
  components: {
    appModuleView: ModuleView
  },
  props: {
    galleryId: {
      type: Number,
      required: false
    }
  },
  methods: {
    async save () {
      this.$v.$touch()
      if (this.$v.$invalid) {
        NotifyService.setErrorMessage(this.$t('notify.please_fill_all_required_fields'))
        return
      }
      try {
        await this.$store.dispatch('gallery/update', this.gallery)
        NotifyService.setSuccessMessage(this.$t('notify.record_was_updated'))
      } catch (err) {
        NotifyService.setErrorMessage(this.$store.getters['gallery/error'])
      }
    }
  },
  async mounted () {
    const previousTabIndex = this.$store.getters['gallery/previousTabIndex']
    this.$refs.galleryForm.tabClick(previousTabIndex)
    this.$store.commit('gallery/setPreviousTabIndex', 0)
    await this.$store.dispatch('gallery/fetchOne', this.galleryId ?? this.$route.params.id)
    if (!this.$store.getters['user/hasPermissionForSite'](this.requiredPermissions.editRoute, this.gallery.site)) {
      this.$router.push(`/gallery/${this.gallery.id}`)
    }
  }
}
</script>
